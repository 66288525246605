.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.pagination li {
  list-style: none;
  margin: 0 5px;
}

.pagination .page-link {
  border: 1px solid #ddd;
  padding: 8px 12px;
  cursor: pointer;
}

.pagination .active .page-link {
  background-color: #750000;

  border-color: black;
}

.pagination .disabled .page-link {
  pointer-events: none;
  background-color: #f5f5f5;
  border-color: #ddd;
  color: #aaa;
}
