.btn-icon {
  background-color: transparent;
}
.btn-icon:hover {
  background-color: transparent;
  color: transparent;
  width: 50px;
}
.btn-compare {
  width: 200px;
  color: blue;
  background-color: transparent;
  height: 30px;
  border: transparent;
}
.btn-compare:hover {
  background-color: azure;
  color: bisque;
}
.compare-icon {
  border: 1px black;
  height: 20px;
  color: orange;
}
.btn-compare:hover {
  background-color: transparent;
}
.compare-div {
  display: flex;
  flex-direction: row;
}

.discount-tag {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #e64b4b;
  color: white;
  padding: 3px 6px;
  border-radius: 4px;
  font-size: 14px;
}
