.filter-container {
  display: flex;
  flex-wrap: wrap;
}

.price-sort-row {
  display: flex;
  align-items: center;
}
.display-options,
.range {
  display: flex;
  align-items: center;
  border-radius: 5px;
}

.show select,
.range select {
  border: none;
  padding: 5px;
  font-size: 14px;
  background-color: #fff;
  border-radius: 3px;
  cursor: pointer;
}

/* .show select:hover,
.range select:hover {
  background-color: #f7f7f7;
} */

.show h5,
.range h5 {
  margin: 0;
  font-size: 16px;
}

.product-list {
  flex-basis: 100%;
}
