.wishlist {
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.wishlist-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  transition: all 0.3s ease;
}

.wishlist-item:hover {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  background-color: #e0d2d2;
}

.item-details {
  display: flex;
  align-items: center;
  flex-grow: 1;
  justify-content: space-between;
}

.item-image {
  width: 60px;
  height: 60px;
  margin-right: 10px;
}

.item-info {
  display: flex;
  align-items: center;
}

.item-name {
  font-weight: bold;
  font-size: 20px;
  font-family: Arial, sans-serif;
}

.add-to-cart-button {
  background-color: transparent;
  border: none;
  padding: 0;
  margin-left: 60px;
  cursor: pointer;
  font-size: 24px;
  color: #3366cc;
  transition: all 0.3s ease;
  width: 80px;
}

/* .add-to-cart-button:hover {
    transform: scale(1.1);
  } */

.add-to-cart-icon {
  vertical-align: middle;
  margin-right: 5px;
}

.delete-button {
  background-color: transparent;
  border: none;
  padding: 0;
  margin-left: 10px;
  cursor: pointer;
  font-size: 20px;
  color: #f44336;
  transition: all 0.3s ease;
  width: 80px;
}

/* .delete-button:hover {
    transform: scale(1.1);
  } */

.wishlist {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.wishlist h2 {
  margin-bottom: 20px;
  font-size: 24px;
}

.wishlist-item {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  padding: 10px;
}

.item-details {
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.item-info {
  display: flex;
  align-items: center;
}

.item-image {
  width: 80px;
  height: 80px;
  margin-right: 10px;
}

.item-column {
  display: flex;
  flex-direction: column;
}

.item-name {
  font-weight: bold;
}

.button-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.add-to-cart-button,
.delete-button {
  background: none;
  border: none;
  cursor: pointer;
  margin-left: 20px;
  transition: transform 0.3s;
}

.add-to-cart-button:hover,
.delete-button:hover {
  transform: scale(1.1);
}

.add-to-cart-button span {
  margin-left: 5px;
}

.empty-wishlist h2 {
  font-size: 24px;
}

.empty-wishlist p {
  font-size: 16px;
}

.empty-wishlist-image {
  width: 100px;
  height: 100px;
}

.wishlist-link {
  font-weight: bold;
}
