.return-form-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

h2 {
  font-size: 20px;
  margin-bottom: 10px;
}

.form-table {
  width: 100%;
  margin-bottom: 20px;
}

label {
  display: block;
  margin-bottom: 5px;
}

input[type="text"],
input[type="email"],
input[type="tel"],
input[type="date"],
textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.error-message {
  color: red;
}

.button-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

button {
  padding: 10px 20px;
  margin-left: 10px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.back-button {
  background-color: #ccc;
}

.continue-button {
  background-color: #bedbbf;
  color: #fff;
}
body {
  font-family: Arial, sans-serif;
  background-color: #f5f5f5;
}

h1 {
  font-size: 28px;
  font-weight: bold;
  color: #333;
  margin: 20px 0;
}

h2 {
  font-size: 22px;
  font-weight: bold;
  color: #333;
  margin: 15px 0;
}

h5 {
  font-size: 16px;
  color: #666;
  margin: 10px 0;
}

.form-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.form-table td {
  padding: 10px;
  vertical-align: top;
  border-bottom: 1px solid #ddd;
}

label {
  display: block;
  font-size: 14px;
  font-weight: bold;
  color: #333;
  margin-bottom: 5px;
}

input[type="text"],
input[type="email"],
input[type="tel"],
input[type="date"],
textarea {
  width: 100%;
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: border-color 0.3s ease-in-out;
}

.error {
  border-color: #ff4d4d !important;
}

.error-message {
  color: #ff4d4d;
  font-size: 12px;
  margin-top: 5px;
}

.center {
  text-align: center;
}

.center-label {
  display: block;
  font-size: 14px;
  font-weight: bold;
  color: #333;
  margin-bottom: 5px;
  text-align: center;
}

.input-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.small-input {
  width: 100px; /* Adjust the width as per your requirement */
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: border-color 0.3s ease-in-out;
}

.error-message {
  color: red;
  font-size: 12px;
  margin-top: 5px;
  text-align: center;
}
.radio-container {
  display: flex;
  align-items: center;
}

.radio-container div {
  margin-right: 10px;
}
.captcha-container {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.captcha label {
  margin-right: 10px;
}

.textarea-input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
}

.return-class {
  display: flex;
}
