.center-header {
  text-align: left;
  padding-left: 40px;
}

.quantity .main-cart-title {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 10px;
}

.bs-canvas-close {
  font-size: 20px;
  color: #888;
}

.cart-item {
  display: flex;
  margin-bottom: 2px;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
}

.cart-product-img {
  width: 110px;
  margin-right: 10px;
  position: relative;
}

.offer-badge {
  background-color: var(--primary-color);
  color: #fff;
  padding: 4px 5px;
  font-size: 12px;
  position: absolute;
  top: 10px;
  right: 10px;
  border-radius: 2px;
}

.cart-text {
  margin-left: 16px;
}

.cart-text h4 {
  font-size: 1.125rem;
  font-weight: 400;
  color: var(--secondary-color);
}

.cart-radio ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.cart-radio label {
  margin-left: 10px;
  color: #555;
  cursor: pointer;
}

.qty-group {
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.minus,
.plus {
  padding: 5px 10px;
  background-color: #f5f5f5;
  border: none;
  cursor: pointer;
  font-weight: bold;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.minus:hover,
.plus:hover {
  background-color: #e0e0e0;
}

.input-text.qty {
  width: 40px;
  text-align: center;
  font-size: 14px;
  border: none;
  border-radius: 3px;
  background-color: #f5f5f5;
  color: #333;
  padding: 5px;
}

.cart-close-btn {
  border: none;
  background-color: transparent;
  cursor: pointer;
  color: #888;
  font-size: 16px;
  transition: color 0.3s ease;
}

.cart-close-btn:hover {
  color: #000;
}

.cart-item-price {
  font-weight: bold;
  color: var(--primary-color);
  font-size: 18px;
  margin-top: 2px;
  font-family: sans-serif, Helvetica, sans-serif;
}

.cart-sidebar-footer {
  margin-top: 20px;
  border-top: 1px solid #ddd;
  padding-top: 10px;
  margin-bottom: -10px;
}

.cart-store-details p {
  margin: 5px 0;
  font-size: 14px;
}

.cart-store-details strong {
  font-weight: bold;
}

.btn-secondary {
  background-color: #000;
  color: #fff;
  border: none;
  padding: 10px 20px;
  text-align: center;
  cursor: pointer;
  font-size: 16px;
  border-radius: 3px;
  transition: background-color 0.3s ease;
}

.quantity {
  display: flex;
  align-items: center;
}

.buttons_added {
  display: flex;
  align-items: center;
}

.decrease-btn,
.increase-btn,
.quantity {
  font-weight: bold;
  border: none;
  background-color: #ffffff;
  color: #000000;
  font-size: 17px;
  padding: 4px 11px;
  cursor: pointer;
}

.decrease-btn:hover,
.increase-btn:hover {
  background-color: #c9e7f0;
}

.quantity {
  margin: 0 8px;
}
.delete-btn {
  /* Add desired styles for the delete button */
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
}
.cart-table th {
  font-weight: bold;
  background-color: #ebebeb;
  padding: 10px;
  text-align: center;
  border-bottom: 1px solid #ddd;
  border-right: 1px solid #ddd;
}

.cart-table th:first-child {
  border-left: 1px solid #ddd;
}

.cart-table th:last-child {
  border-right: none;
}
@media (max-width: 602px) {
  button .delete-btn {
    margin-left: 5rem;
  }
}

/* cart.css */
.cart-sidebar {
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  max-width: 800px;
  margin: 3rem auto;
}

.cart-item {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.cart-product-img img {
  object-fit: cover;
}

.cart-text {
  margin-left: 15px;
}

.qty-group {
  display: flex;
  align-items: center;
}

.quantity {
  background-color: rgb(202, 202, 202);
  margin: 0 5px;
  padding: 5px 10px;
  border-radius: 4px;
}

.cart-item-price {
  font-weight: bold;
  margin-left: 10px;
}

.delete-btn-container {
  cursor: pointer;
}

.delete-btn {
  width: 22px;
  height: 22px;
  transition: transform 0.2s;
}

.delete-btn:hover {
  transform: scale(1.2);
}
.cart-empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 300px;
}

.cart-empty h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

.add-item-button {
  padding: 10px 20px;
  background-color: #42b883;
  color: #fff;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.add-item-button:hover {
  background-color: #3da86a;
}
.cart-logo {
  width: 150px;
  height: 150px;
  margin-bottom: 20px;
}
.gap {
  margin-top: 20px;
}
