.card-container {
  display: flex;
  flex-direction: row;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 20px;
  border: 5px solid black;
}

.card {
  border: 1px solid #ccc;
  padding: 20px;
  justify-content: center;
}

.read-more {
  color: blue;
  cursor: pointer;
}
.cart-btn {
  width: 100px;
}

.card h3 {
  min-height: 60px;
}

.card p {
  margin-bottom: 10px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Show only 3 lines */
  -webkit-box-orient: vertical;
}

.read-more {
  color: blue;
  cursor: pointer;
}
.search-content .card-container {
  flex-wrap: nowrap;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.pagination li {
  list-style: none;
  margin: 0 5px;
}

.pagination .page-link {
  border: 1px solid #ddd;
  padding: 8px 12px;
  cursor: pointer;
}

.pagination .active .page-link {
  background-color: #750000;

  border-color: black;
}

.pagination .disabled .page-link {
  pointer-events: none;
  background-color: #f5f5f5;
  border-color: #ddd;
  color: #aaa;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.pagination-button {
  background-color: #f2f2f2;
  border: none;
  padding: 8px 12px;
  margin: 0 4px;
  cursor: pointer;
  font-size: 14px;
  color: #333;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.pagination-button:hover {
  background-color: #750000;
  border-color: blue;
}

.pagination-button.active {
  background-color: #750000;
  color: #fff;
}

.pagination-button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}
