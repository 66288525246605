.blog-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 8px;
}

.blog-image img {
  max-width: 100%;
  margin-bottom: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}

.blog-content h2 {
  font-size: 28px;
  margin-bottom: 10px;
  color: #333;
  font-family: "Arial", sans-serif;
}

.blog-content p {
  font-size: 18px;
  line-height: 1.5;
  color: #666;
  font-family: "Arial", sans-serif;
}
