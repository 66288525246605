.container-fluid {
  max-width: 1200px;
  margin: 0 auto;
  padding: 10px 15px;
}

.featured-product-list {
  margin-top: 20px;
}

.product-box {
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.product-image img {
  max-width: 100%;
  height: 100%;
}

.product-text h5 {
  font-size: 18px;
  font-weight: bold;
}

.add-cart {
  margin-top: 15px;
}

.grid-view {
  height: auto;
}

.product-list .list-item {
  display: flex;
  margin-bottom: 20px;
}

.pagination-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.pagination {
  list-style: none;
  display: flex;
  gap: 10px;
}

.page-item {
  display: flex;
}

.page-link {
  padding: 5px 10px;
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  color: #333;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.page-link.active,
.page-link:hover {
  background-color: #750000;
  border: 1px solid black;
  color: #fff;
}

.page-link.disabled {
  cursor: not-allowed;
}
